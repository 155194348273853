<template>
  <div
    class="bt"
    v-loading="loading"
    :element-loading-text="loadingText"
    :style="getStyle"
  >
    <template v-if="!hideTopbar">
      <div class="outer-div">
        <div class="outer-div">
          <el-button
            v-if="!fromCustomDashboard && !this.$route.query.routeType"
            icon="el-icon-back"
            size="mini"
            @click="backToEntitiesViews"
          ></el-button>
          <div v-if="!fromCustomDashboard" class="head-title text-dark ml-1">
            {{ payload.name }}
          </div>
          <b v-else class="mt-3 ml-2">{{ component.component_name }}</b>
        </div>
        <div>
          <el-input
            v-if="fromCustomDashboard"
            size="mini"
            prefix-icon="el-icon-search"
            style="width: 200px; margin-right: 5px"
            placeholder="Search"
            v-model="search_string"
            @input="search"
            clearable
          ></el-input>
          <el-button
            v-if="payload.filter_fields && payload.filter_fields.length"
            :type="fromCustomDashboard ? 'text' : 'primary'"
            :icon="!isFilterOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            size="mini"
            @click="openFiltersCollapse"
            >{{
              !fromCustomDashboard
                ? !isFilterOpen
                  ? payload.filter_open
                  : payload.filter_close
                : ""
            }}</el-button
          >
          <el-dropdown
            trigger="click"
            v-if="fromCustomDashboard && !hide_options"
          >
            <el-link
              icon="el-icon-more"
              :underline="false"
              style="transform: rotate(90deg); height: 40px"
            ></el-link>

            <el-dropdown-menu slot="dropdown">
              <a @click="editComponent">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            icon="el-icon-full-screen"
            type="text"
            v-if="fromCustomDashboard"
            @click="expandEntityView"
          >
          </el-button>
        </div>
      </div>
      <div
        v-if="
          isFilterOpen && payload.filter_fields && payload.filter_fields.length
        "
      >
        <div class="filters-cards">
          <div
            v-for="(filter, i) in payload.filter_fields"
            :key="filter.key + '_' + i"
          >
            <label class="text-dark" v-if="!fromCustomDashboard">
              {{ filter.label }}:
              <span class="error" v-if="filter.required">*</span></label
            >
            <div v-if="filter.type == 'ENTITY'">
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  v-model="selectedFileds[filter.key]"
                  filterable
                  :multiple="filter.multiple ? true : false"
                  collapse-tags
                  :placeholder="'Select ' + filter.label"
                  :style="'width: 100%;'"
                  :loading="checkLoading(filter.key)"
                  :disabled="
                    isApplicationUserSide &&
                    filter.isApplicationUser &&
                    getActiveContactType.contact_type._id == filter.entity_id
                  "
                >
                  <el-option value="all" label="All" v-if="!filter.required">
                  </el-option>
                  <template v-if="!refresh">
                    <el-option
                      v-for="(temp, i) in allEntityDataList(filter.entity_id)"
                      :key="temp._id + '_' + i"
                      :value="temp._id"
                      :label="getLabel(temp, i, filter.entity_id)"
                    >
                    </el-option>
                  </template>
                </el-select>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'DATE' && filter.filter_type == 'PICKER_OPTIONS'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  :placeholder="'Pick date range'"
                  v-model="selectedFileds[filter.key]"
                  @change="
                    (changedValue) => setDatePickerDate(changedValue, false)
                  "
                  :style="'width: 100%;'"
                >
                  <el-option
                    v-for="(el, index) in pickerOptions"
                    :key="el.value + '_' + index"
                    :value="el.value"
                    :label="el.label"
                    >{{ el.label }}
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'DATE' && filter.filter_type == 'DATE_RANGE'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-date-picker
                  v-model="selectedFileds[filter.key]"
                  type="daterange"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                >
                </el-date-picker>
              </el-tooltip>
            </div>
            <div
              v-else-if="
                filter.type == 'SELECT' || filter.type == 'MULTI_SELECT'
              "
            >
              <el-tooltip :content="filter.label" placement="top-start">
                <el-select
                  v-model="selectedFileds[filter.key]"
                  filterable
                  :multiple="filter.multiple ? true : false"
                  collapse-tags
                  :placeholder="'Select ' + filter.label"
                  :style="'width: 100%;'"
                >
                  <el-option value="all" label="All" v-if="!filter.required">
                  </el-option>
                  <el-option
                    v-for="(temp, i) in getFieldOptions(filter)"
                    :key="temp + '_' + i"
                    :value="temp"
                    :label="temp"
                  >
                  </el-option>
                </el-select>
              </el-tooltip>
            </div>
          </div>
          <div
            :style="`margin-top: ${
              payload.filter_fields.length > 4 ? 20 : 5
            }px;float:${fromCustomDashboard ? 'right' : 'none'}`"
          >
            <el-tooltip content="Run" placment="top-start">
              <Button
                class="bt btn btn-outline-primary btn-sm m-lr-1"
                type="button"
                @click="applyFilters"
                ><i class="fa fa-cogs" aria-hidden="true"></i>
                {{ fromCustomDashboard ? "" : "Run" }}</Button
              >
            </el-tooltip>
            <el-tooltip content="Reset" placment="top-start">
              <Button
                type="button"
                class="bt btn btn-outline-secondary btn-sm m-lr-1"
                @click="resetFilters"
                ><i class="fa fa-refresh" aria-hidden="true"></i>
                {{ fromCustomDashboard ? "" : "Reset" }}</Button
              >
            </el-tooltip>
            <el-dropdown placement="bottom" v-if="data && data.length">
              <Button
                type="button"
                class="bt btn btn-sm btn-outline-secondary m-lr-1"
                ><i class="fa fa-upload"></i
                >{{ fromCustomDashboard ? "" : "Export" }}</Button
              >
              <el-dropdown-menu>
                <a @click="exportToExcel">
                  <el-dropdown-item>
                    <i class="fa fa-file-excel-o"></i>
                    {{ fromCustomDashboard ? "Excel" : "Export as Excel" }}
                  </el-dropdown-item>
                </a>
                <a @click="exportToPDF" v-if="pdfExport">
                  <el-dropdown-item>
                    <i class="fa fa-file-pdf-o"></i>
                    {{ fromCustomDashboard ? "PDF" : "Export as PDF" }}
                  </el-dropdown-item>
                </a>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <el-row v-if="!fromCustomDashboard">
        <el-col class="mb-1 mt-1">
          <el-input
            size="mini"
            prefix-icon="el-icon-search"
            style="width: 250px; float: right"
            placeholder="Search"
            v-model="search_string"
            @input="search"
            clearable
          ></el-input>
        </el-col>
      </el-row>
    </template>
    <el-scrollbar wrap-style="max-height: 80vh;">
      <div class="main-table-holder mt-1">
        <div class="vue-data-table-default">
          <el-table
            :data="searchData"
            :resizable="true"
            style="width: 100%"
            class="sidemenu"
            border
            :show-summary="payload.include_total"
            :summary-method="getSummaries"
            :default-sort="getDefaultSort"
            :max-height="fromCustomDashboard ? (isFilterOpen ? 250 : 297) : 500"
          >
            <el-table-column
              v-if="payload.add_serial_number"
              label="S.No."
              id="sno"
              prop="sno"
              width="75"
            >
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column
              v-for="(step, i) in removeHiddenColumns(
                payload.main_columns || []
              )"
              :key="step.key + '_' + i"
              :label="step.label"
              :id="step.key"
              :prop="getProp(step)"
              :width="step.label.length > 12 ? step.label.length * 12 : 150"
              :align="step.alignment ? step.alignment : 'left'"
              sortable
            >
              <template slot-scope="scope">
                <!-- {{step.input_type}} -->
                <span
                  v-if="
                    scope.row[getProp(step)] == 'MULTIPLE' &&
                    step.allow_view_details &&
                    step.detailed_entity_view_id
                  "
                >
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="openDetailedView(scope.$index, step)"
                  >
                    Multiple
                  </el-link>
                </span>
                <span
                  v-else-if="
                    getField(step) &&
                    getField(step).inputType == 'DATE' &&
                    scope.row[getProp(step)] !== step.default_value
                  "
                >
                  {{ scope.row[getProp(step)] | globalDateFormat }}
                </span>
                <span
                  v-else-if="
                    getField(step) &&
                    getField(step).inputType == 'DATE_TIME' &&
                    scope.row[getProp(step)] !== step.default_value
                  "
                >
                  {{ scope.row[getProp(step)] | globalDateTimeFormat }}
                </span>
                <span
                  v-else-if="
                    getField(step) &&
                    getField(step).inputType == 'FILE' &&
                    scope.row[getProp(step)]
                  "
                >
                  <el-button
                    type="text"
                    style="margin-top: -14px"
                    @click="downloadFile(scope.row[getProp(step)])"
                    class="mb-1"
                  >
                    <p style="font-size: 13px">
                      Download <i class="el-icon-download"></i>
                    </p>
                  </el-button>
                </span>
                <span
                  v-else-if="
                    getField(step) &&
                    getField(step).input_type == 'FORMULA' &&
                    isDate(scope.row[getProp(step)])
                  "
                >
                  <p>
                    {{ scope.row[getProp(step)] | globalDateFormat }}
                  </p>
                </span>
                <span
                  v-else-if="
                    getField(step) &&
                    (getField(step).inputType == 'AGGREGATE_FUNCTION' ||
                      getField(step).inputType == 'CURRENCY' ||
                      getField(step).inputType == 'FORMULA')
                  "
                >
                  {{
                    getCurrencyFormat(scope.row[getProp(step)], getField(step))
                  }}
                </span>
                <span v-else>
                  {{ scope.row[getProp(step)] }}
                </span>
              </template>
              <!-- <template slot-scope="scope">
                <div
                  :style="getFieldStyle(step, scope.row)"
                  v-if="step.main_entity_field && step.field_type == 'DISPLAY'"
                >
                  {{ getFieldValue(scope.row, step) }}
                </div>
                <div v-else-if="step.entity_id && step.field_type == 'FILTER'">
                  <span
                    v-if="
                      appliedFilters &&
                      appliedFilters[step.template_id + '#' + step.key] &&
                      (allEntityDataList(step.entity_id) || []) &&
                      (allEntityDataList(step.entity_id) || []).find(
                        (e) =>
                          e._id ==
                          appliedFilters[step.template_id + '#' + step.key]
                      )
                    "
                  >
                    {{
                      getLabel(
                        (allEntityDataList(step.entity_id) || []).find(
                          (e) =>
                            e._id ==
                            appliedFilters[step.template_id + "#" + step.key]
                        ),
                        scope.$index + 1,
                        step.entity_id
                      )
                    }}
                  </span>
                  <span v-else> All</span>
                </div>
                <div v-if="!step.main_entity_field && step.linked_entity_id">
                  <span v-if="step.field_type == 'SUM'">
                    {{ getOtherEntityFieldValue(scope.row, step, step.key) }}
                  </span>
                  <span v-else-if="step.field_type == 'DISPLAY'">
                    <span
                      v-if="
                        getOtherEntityFieldValue(scope.row, step, step.key) &&
                        typeof getOtherEntityFieldValue(
                          scope.row,
                          step,
                          step.key
                        ) == 'object' &&
                        getOtherEntityFieldValue(scope.row, step, step.key)
                          .length == 1
                      "
                    >
                      {{
                        checkIsDateField(
                          getOtherEntityFieldValue(
                            scope.row,
                            step,
                            step.key
                          )[0][step.key]
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        getOtherEntityFieldValue(scope.row, step, step.key) &&
                        typeof getOtherEntityFieldValue(
                          scope.row,
                          step,
                          step.key
                        ) == 'object' &&
                        getOtherEntityFieldValue(scope.row, step, step.key)
                          .length > 1
                      "
                    >
                      <el-link
                        type="primary"
                        :underline="false"
                        @click="openDetailedView(scope.row, step)"
                      >
                        Multiple
                      </el-link>
                    </span>
                    <span v-else>{{ step.default_value }}</span>
                  </span>
                  <span v-else-if="step.field_type == 'AGGREGATION_SUM'">
                    <span
                      v-if="
                        getOtherEntityFieldValue(
                          scope.row,
                          step,
                          step.data_table_key
                        )
                      "
                    >
                      {{ sumOfArray(scope.row, step, step.data_table_key) }}
                    </span>
                    <span v-else> 0 </span>
                  </span>
                </div>
                <div v-else-if="step.field_type == 'FORMULA'">
                  {{ getFormulaValue(scope.row, step) }}
                </div>
              </template> -->
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-scrollbar>
    <div
      class="d-flex mt-2"
      v-if="payload && payload.aggregations && payload.aggregations.length"
      style="float: right"
    >
      <div
        v-for="(aggregation, index) in payload.aggregations"
        :key="index"
        class="ml-4"
      >
        <b>{{ aggregation.label }}</b
        ><br />
        <span>{{ aggregationValues[aggregation.label] }}</span>
      </div>
    </div>
    <el-dialog
      :visible.sync="detailedViewModal"
      :fullscreen="true"
      :title="detailedEntityViewTitle"
    >
      <div>
        <EntityDetailedView
          v-if="detailedEntityViewId && detailedViewModal"
          :hideTopbar="true"
          :filtersData="detailedViewFilters"
          :entityViewId="detailedEntityViewId"
        >
        </EntityDetailedView>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          @click="closeDetailedView"
          class="btn btn-outline-danger btn-sm m-lr-1"
          type="button"
        >
          <i class="fa fa-times"></i>Cancel
        </button>
      </span>
    </el-dialog>
    <el-dialog
      :width="getIsMobile ? '100%' : '30%'"
      :visible.sync="openDialogVisible"
      title="Edit Component"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-loading="loading">
        <CustomComponentEdit
          :addStatsData="updateComponentData"
          :getRelationshipEntities="[]"
          :selectedEntity="{}"
          :globalVariablesData="[]"
        ></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          class="btn btn btn-outline-danger btn-sm m-lr-1"
          type="button"
          @click="openDialogVisible = false"
        >
          <i class="fa fa-close"></i> Cancel
        </button>
        <button
          class="btn btn btn-outline-primary btn-sm m-lr-1"
          type="button"
          @click="updateComponent"
        >
          <i class="fa fa-sign-out"></i> Save
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as XLSX from "xlsx";
import moment from "moment";
import NavigationHelper from "@/mixins/navigationHelper";
import AutomationHelper from "@/mixins/AutomationHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import * as mexp from "math-expression-evaluator";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import { postAPICall } from "../../helpers/httpHelper";
export default {
  watch: {
    "$route.params.entity_view_id": {
      async handler(entity_view_id) {
        await this.getNecessaryInfo(entity_view_id);
      },
    },
    selectedFileds: {
      async handler(data) {
        let newForm = {};
        this.payload.filter_fields.forEach((e) => {
          if (e.filters) {
            (e.filters || []).forEach((f) => {
              newForm[f.value_field] = data[f.value_field];
            });
          }
        });
        if (JSON.stringify(this.filterForm) !== JSON.stringify(newForm)) {
          this.filterForm = {
            ...this.filterForm,
            ...JSON.parse(JSON.stringify(newForm)),
          };
          (this.payload.filter_fields || []).forEach((filter) => {
            if (
              filter.type == "ENTITY" &&
              filter.filters &&
              filter.filters.find(
                (e) =>
                  e.data_source == "self_field" || e.data_source == "TEMPLATE"
              )
            ) {
              this.$set(
                this.selectedFileds,
                filter.key,
                filter.multiple ? ["all"] : "all"
              );
            }
          });
          this.fetchDependentEnityData();
        }
      },
      deep: true,
    },
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilter?.entity_id && this.isRelatedToGlobalFilter) {
          this.mapGlobalFilters();
          this.checkAndApplyFilters();
        }
      },
    },
  },
  props: [
    "hideTopbar",
    "filtersData",
    "entityViewId",
    "fromCustomDashboard",
    "index",
    "component",
    "customDashboardData",
    "hide_options",
  ],
  components: {
    EntityDetailedView: () => import("./EntityViews.vue"),
    CustomComponentEdit: () =>
      import("@/components/customDashboard/customComponentEdit.vue"),
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityViewsData",
      "getEntityRecordsForTable",
      "getEntityViewsPDFExport",
    ]),
    ...mapGetters("entityViews", ["getEntityViewById"]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getGlobalFiltervalue",
      "getActiveWorkspace",
      "getActiveContactType",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("templatesData", ["getDownloadUrl", "getDownloadError"]),
    ...mapGetters("applicationUsersPermission", ["getEntityPermission"]),
    getStyle() {
      return this.fromCustomDashboard
        ? `border: 1px solid #eaeaea;border-radius: 5px;min-height: 300px !important;margin-bottom: 0px;margin-top: 0px;box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1) !important;`
        : "";
    },
    checkGlobalFilter() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        return filter ? filter : null;
      }
      return null;
    },
    checkLoading() {
      return (key) => {
        return this.loadingFilters[key];
      };
    },
    getDefaultSort() {
      let defaultSortColumn = (this.payload.main_columns || []).find(
        (e) => e.is_default_sort
      );
      if (defaultSortColumn) {
        return {
          prop: this.getProp(defaultSortColumn),
          order: defaultSortColumn.sort_order,
        };
      }
      if (this.payload?.main_columns && this.payload.main_columns[0]) {
        return {
          prop: this.getProp(this.payload.main_columns[0]),
          order: "descending",
        };
      }
      return {};
    },
  },
  mixins: [
    NavigationHelper,
    AutomationHelper,
    CustomDashboardConfig,
    TemplateBuilderhelper,
    EntityHelper,
  ],
  data() {
    return {
      loading: false,
      data: [],
      searchData: [],
      isFilterOpen: true,
      entityDataMapping: {},
      selectedFileds: {},
      appliedFilters: {},
      selectedEntityFields: [],
      updateComponentData: null,
      openDialogVisible: false,
      //don't remove it
      // payload: {
      //   filter_open: "Show",
      //   filter_close: "Hide",
      //   add_serial_number: true,
      //   include_total: true,
      //   include_filters_in_export: true,
      //   include_totals_in_export: true,
      //   status: "ACTIVE",
      //   _id: "651a4e3ce62e704f287f0f18",
      //   name: "Stock report",
      //   entity_id: "64c8cb2a70b1d070636b0673",
      //   other_entities: [
      //     {
      //       entity_id: "64e352e6e290949532786747",
      //       linked_field: "64e352dbe29094953278666a#items",
      //     },
      //     {
      //       entity_id: "64e09676a946882b070792ea",
      //       linked_field: "64e09521a946882b070781ce#item_name",
      //     },
      //   ],
      //   filter_fields: [
      //     {
      //       main_entity_field: true,
      //       main_entity: false,
      //       required: true,
      //       multiple: false,
      //       filters: [],
      //       label: "Corporation",
      //       key: "64b4dafaa4a484a54c48ac22#corporation",
      //       entity_id: "64b21d04e21d887bb779f806",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       type: "ENTITY",
      //       linked_entity_ids: [],
      //     },
      //     {
      //       main_entity_field: true,
      //       main_entity: false,
      //       required: false,
      //       multiple: false,
      //       filters: [],
      //       label: "Department",
      //       key: "64b4dafaa4a484a54c48ac22#department",
      //       entity_id: "64e09399a946882b070771d3",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       type: "ENTITY",
      //       linked_entity_ids: [],
      //     },
      //     {
      //       main_entity_field: false,
      //       main_entity: false,
      //       required: false,
      //       multiple: false,
      //       filters: [
      //         {
      //           field: "self#related_to/1",
      //           operator: "=",
      //           value: "",
      //           query_type: "AND",
      //           relation_entity_id: "64b21d04e21d887bb779f806",
      //           data_source: "self_field",
      //           value_field: "64b4dafaa4a484a54c48ac22#corporation",
      //           data_type: "RELATION",
      //         },
      //       ],
      //       label: "Location",
      //       key: "64e352dbe29094953278666a#location",
      //       entity_id: "64b4e382a4a484a54c48b812",
      //       linked_entity_ids: [
      //         {
      //           entity_id: "64e352e6e290949532786747",
      //           key: "64e352dbe29094953278666a#location",
      //         },
      //         {
      //           entity_id: "64e09676a946882b070792ea",
      //           key: "64e09521a946882b070781ce#location",
      //         },
      //       ],
      //       type: "ENTITY",
      //     },
      //     {
      //       main_entity_field: false,
      //       main_entity: true,
      //       required: false,
      //       multiple: false,
      //       filters: [
      //         {
      //           field: "self#related_to/1",
      //           operator: "=",
      //           value: "",
      //           query_type: "AND",
      //           relation_entity_id: "64b21d04e21d887bb779f806",
      //           data_source: "self_field",
      //           value_field: "64b4dafaa4a484a54c48ac22#corporation",
      //           data_type: "RELATION",
      //         },
      //       ],
      //       label: "Item",
      //       key: "currentEntity",
      //       entity_id: "64c8cb2a70b1d070636b0673",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       type: "ENTITY",
      //       linked_entity_ids: [],
      //     },
      //     {
      //       main_entity_field: false,
      //       main_entity: false,
      //       required: false,
      //       multiple: false,
      //       filters: [],
      //       label: "View Transaction",
      //       key: "64e352dbe29094953278666a#date/picker",
      //       linked_entity_ids: [
      //         {
      //           entity_id: "64e352e6e290949532786747",
      //           key: "64e352dbe29094953278666a#date",
      //         },
      //         {
      //           entity_id: "64e09676a946882b070792ea",
      //           key: "64e09521a946882b070781ce#date_created",
      //         },
      //       ],
      //       type: "DATE",
      //       filter_type: "PICKER_OPTIONS",
      //     },
      //     {
      //       main_entity_field: false,
      //       main_entity: false,
      //       required: false,
      //       multiple: false,
      //       filters: [],
      //       label: "From",
      //       key: "64e352dbe29094953278666a#date/range",
      //       linked_entity_ids: [
      //         {
      //           entity_id: "64e352e6e290949532786747",
      //           key: "64e352dbe29094953278666a#date",
      //         },
      //         {
      //           entity_id: "64e09676a946882b070792ea",
      //           key: "64e09521a946882b070781ce#date_created",
      //         },
      //       ],
      //       type: "DATE",
      //       filter_type: "DATE_RANGE",
      //     },
      //   ],
      //   main_columns: [
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: false,
      //       detailed_view_type: "ENTITY",
      //       label: "Department",
      //       key: "department",
      //       template_id: "64b4dafaa4a484a54c48ac22",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       entity_id: "64e09399a946882b070771d3",
      //       field_type: "FILTER",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: false,
      //       detailed_view_type: "ENTITY",
      //       label: "Location",
      //       key: "location",
      //       template_id: "64e352dbe29094953278666a",
      //       linked_entity_id: "64e352e6e290949532786747",
      //       entity_id: "64b4e382a4a484a54c48b812",
      //       field_type: "FILTER",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: false,
      //       detailed_view_type: "ENTITY",
      //       label: "Item Name",
      //       key: "name",
      //       template_id: "64b4dafaa4a484a54c48ac22",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       main_entity_field: true,
      //       field_type: "DISPLAY",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: false,
      //       detailed_view_type: "ENTITY",
      //       label: "Description",
      //       key: "description",
      //       template_id: "64b4dafaa4a484a54c48ac22",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       main_entity_field: true,
      //       field_type: "DISPLAY",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: false,
      //       detailed_view_type: "ENTITY",
      //       label: "UOM",
      //       key: "uom",
      //       linked_entity_id: "64c8cb2a70b1d070636b0673",
      //       template_id: "64b4dafaa4a484a54c48ac22",
      //       main_entity_field: true,
      //       field_type: "DISPLAY",
      //     },
      //     {
      //       selected_fields: [
      //         {
      //           type: "PARENTHESES",
      //           value: "(",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "inventory_quantity",
      //           template_id: "64e09521a946882b070781ce",
      //           selected_date_offset: "BEFORE",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "-",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "quantity",
      //           template_id: "64e352dbe29094953278666a",
      //           selected_date_offset: "BEFORE",
      //         },
      //         {
      //           type: "PARENTHESES",
      //           value: ")",
      //         },
      //       ],
      //       label: "QTY Opening",
      //       key: "qty_opening",
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       main_entity_field: false,
      //       field_type: "FORMULA",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY Opening",
      //       key: "inventory_quantity",
      //       template_id: "64e09521a946882b070781ce",
      //       linked_entity_id: "64e09676a946882b070792ea",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //       selected_date_offset: "BEFORE",
      //       is_hide_column: true,
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY Added",
      //       key: "inventory_quantity",
      //       template_id: "64e09521a946882b070781ce",
      //       linked_entity_id: "64e09676a946882b070792ea",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY Used",
      //       key: "qty_used",
      //       template_id: "64e352dbe29094953278666a",
      //       linked_entity_id: "64e352e6e290949532786747",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY Wasted",
      //       key: "qty_wasted",
      //       template_id: "64e352dbe29094953278666a",
      //       linked_entity_id: "64e352e6e290949532786747",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY Theft",
      //       key: "qty_theft",
      //       template_id: "64e352dbe29094953278666a",
      //       linked_entity_id: "64e352e6e290949532786747",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //     },
      //     {
      //       selected_fields: [],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "total used",
      //       key: "quantity",
      //       template_id: "64e352dbe29094953278666a",
      //       linked_entity_id: "64e352e6e290949532786747",
      //       main_entity_field: false,
      //       field_type: "SUM",
      //       selected_date_offset: "BEFORE",
      //       is_hide_column: true,
      //     },
      //     {
      //       selected_fields: [
      //         {
      //           type: "PARENTHESES",
      //           value: "(",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "inventory_quantity",
      //           template_id: "64e09521a946882b070781ce",
      //           selected_date_offset: "BEFORE",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "-",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "quantity",
      //           template_id: "64e352dbe29094953278666a",
      //           selected_date_offset: "BEFORE",
      //         },
      //         {
      //           type: "PARENTHESES",
      //           value: ")",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "+",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "inventory_quantity",
      //           template_id: "64e09521a946882b070781ce",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "-",
      //         },
      //         {
      //           type: "PARENTHESES",
      //           value: "(",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "qty_used",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "+",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "qty_wasted",
      //         },
      //         {
      //           type: "OPERATOR",
      //           value: "+",
      //         },
      //         {
      //           type: "FIELD",
      //           key: "qty_theft",
      //         },
      //         {
      //           type: "PARENTHESES",
      //           value: ")",
      //         },
      //       ],
      //       alignment: "left",
      //       ignore_if_empty: true,
      //       detailed_view_type: "ENTITY",
      //       label: "QTY on Hand",
      //       key: "qty_on_hand",
      //       main_entity_field: false,
      //       field_type: "FORMULA",
      //     },
      //   ],
      //   created_by: "6430007bd4b91f24157fbb76",
      //   updated_by: "6430007bd4b91f24157fbb76",
      //   company_id: "64b13c8ed49868759f9135f6",
      //   created_at: "2023-10-02T04:59:40.987Z",
      //   updated_at: "2023-10-11T04:20:28.494Z",
      //   __v: 0,
      // },
      payload: {},
      pickerOptions: [
        {
          label: "Today",
          value: "today",
        },
        {
          label: "Yesterday",
          value: "yesterday",
        },
        {
          label: "Last week",
          value: "last_week",
        },
        {
          label: "This week",
          value: "this_week",
        },
        {
          label: "Next week",
          value: "next_week",
        },
        {
          label: "Last month",
          value: "last_month",
        },
        {
          label: "This month",
          value: "this_month",
        },
        {
          label: "Next month",
          value: "next_month",
        },
        {
          label: "Last quarter",
          value: "last_quarter",
        },
        {
          label: "This quarter",
          value: "this_quarter",
        },
        {
          label: "Next quarter",
          value: "next_quarter",
        },
        {
          label: "Last year",
          value: "last_year",
        },
        {
          label: "This year",
          value: "this_year",
        },
        {
          label: "Next year",
          value: "next_year",
        },
      ],
      default_date: "this_year",
      currentEntity: {},
      entitytemplateColorRules: {},
      filterForm: {},
      dependentFilters: [],
      loadingFilters: {},
      refresh: false,
      templateFieldsObj: {},
      detailedViewModal: false,
      detailedViewFilters: [],
      detailedEntityViewId: null,
      detailedEntityViewTitle: "",
      entityViewData: [],
      isRelatedToGlobalFilter: false,
      loadingText: "Fetching data...",
      pdfExport: false,
      search_string: "",
      isApplicationUserSide: false,
      aggregationValues: {},
      otherEntitiesAllFields: {},
    };
  },
  async mounted() {
    if (this.$route.name == "ApplicationUserEntityViews") {
      this.isApplicationUserSide = true;
    }
    if (this.entityViewId) {
      await this.getNecessaryInfo(this.entityViewId);
    } else if (this.$route.params.entity_view_id) {
      await this.getNecessaryInfo(this.$route.params.entity_view_id);
    }
    // this.checkAndGetFilters();
    // this.reset();
    // this.setDatePickerDate(this.default_date, true);
    // this.mapEntityViewFiltersData();
    // await Promise.all([
    //   this.getRequiredTemplates(),
    //   this.checkAndApplyFilters(),
    //   this.fetchFilterEntityData(),
    // ]);
  },
  methods: {
    getDataTableField(tempId, key) {
      return this.templateFieldsObj?.[tempId]
        ?.filter(
          (fd) =>
            fd.input_type == "DATA_TABLE" || fd.input_type == "ENTITY_TABLE"
        )
        ?.find(
          (fd) => fd.data_table_columns.filter((dc) => dc.key == key).length
        )
        ?.data_table_columns.find((dc) => dc.key == key);
    },
    getAggregationValue(aggregation) {
      if (aggregation?.result_type == "PERCENTAGE") {
        return (
          (!isNaN(this.aggregationValues[aggregation.label])
            ? this.aggregationValues[aggregation.label]
            : "-") + "%"
        );
      } else if (aggregation?.result_type == "CURRENCY") {
        let field = aggregation.selected_fields.find(
          (fd) => fd.result_type == "CURRENCY"
        );
        let column = this.payload.main_columns.find(
          (fd) =>
            fd.template_id == field.template_id &&
            fd.key == field.key &&
            fd?.filter == field?.filter
        );
        let value = this.getCurrencyFormat(
          this.aggregationValues[aggregation.label],
          this.getField(column)
        );
        return !isNaN(this.aggregationValues[aggregation.label]) ? value : "-";
      } else {
        return !isNaN(this.aggregationValues[aggregation.label])
          ? this.aggregationValues[aggregation.label]
          : "-";
      }
    },
    async updateComponent() {
      this.updateComponentData.component_name =
        this.updateComponentData.component_name.trim();
      if (!this.updateComponentData.component_name) {
        this.$message.error("Please enter Entity view name");
        return;
      }
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
            this.updateComponentData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      }
      this.openDialogVisible = false;
      if (
        this.updateComponentData.entity_view_id !==
        this.component.entity_view_id
      ) {
        this.component = { ...this.updateComponentData };
        await this.getNecessaryInfo(this.component.entity_view_id);
      } else {
        this.component = { ...this.updateComponentData };
      }
      this.$emit("viewUpdate", {
        index: this.index,
        data: this.component,
      });
    },
    editComponent() {
      this.updateComponentData = { ...this.component };
      this.openDialogVisible = true;
    },
    deleteComponent() {
      this.$confirm("Are you sure to delete the Entity View?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    expandEntityView() {
      this.component.isExpandTable = !this.component.isExpandTable;
      this.$emit("expendTable", this.index);
    },
    search() {
      this.loading = true;
      this.searchData = [];
      this.searchData = this.data.filter((dt) =>
        Object.values(dt)
          .map((val) => {
            return val.toString().toLowerCase();
          })
          .some((val) => val.includes(this.search_string.toLowerCase()))
      );
      this.fetchAggregationValues();
      this.loading = false;
    },
    async downloadFile(rawData) {
      let data = JSON.parse(rawData);
      let fileName = data.name;
      fileName = await fileName.replace(/\//g, "-");
      fileName = await fileName.replace(/ /g, "_");
      fileName = await fileName.replace(/[()]/g, "");
      if (data && !data.path) {
        data.path = `template-data-documents/${fileName}`;
      }
      await this.$store.dispatch("templatesData/downloadTemplateDocument", {
        path: data.path,
      });
      await fetch(this.getDownloadUrl, {
        method: "GET",
        responseType: "blob",
      })
        .then((res) => res.blob())
        .then((response) => {
          var fileURL = window.URL.createObjectURL(response);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      if (this.getDownloadUrl) {
        // window.open(this.getDownloadUrl, "_blank");
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getDownloadError || "Error while downloading",
        });
      }
    },
    removeHiddenColumns(colums) {
      return colums.filter((e) => {
        if (!e?.is_hide_column) {
          return e;
        }
      });
    },
    openDetailedView(index, step) {
      let data = this.entityViewData[index];
      if (step?.allow_view_details && step?.detailed_entity_view_id && data) {
        this.detailedEntityViewId = step.detailed_entity_view_id;
        this.detailedViewFilters = [
          {
            entity_id: step.linked_entity_id,
            value:
              data[
                step.linked_entity_id + "#" + step.template_id + "#" + step.key
              ] &&
              data[
                step.linked_entity_id + "#" + step.template_id + "#" + step.key
              ].length
                ? data[
                    step.linked_entity_id +
                      "#" +
                      step.template_id +
                      "#" +
                      step.key
                  ].flatMap((e) => e._id)
                : [],
          },
          {
            entity_id: this.currentEntity._id,
            value: [data._id],
          },
        ];
        this.detailedEntityViewTitle = step.detailed_view_title
          ? step.detailed_view_title
          : this.getPrimaryStepDetails(data);
        this.detailedViewModal = true;
      }
    },
    getPrimaryStepDetails(data) {
      let text = "",
        count = 0;
      (this.payload.main_columns || []).forEach((step) => {
        if (
          step.main_entity_field &&
          step.field_type == "DISPLAY" &&
          count < 3
        ) {
          text =
            text +
            (step.label + ": " + this.getFieldValue(data, step)) +
            (count < 2 ? "," : "");
          count++;
        }
      });
      return text;
    },
    closeDetailedView() {
      this.detailedEntityViewId = null;
      this.detailedViewFilters = [];
      this.detailedEntityViewTitle = "";
      this.detailedViewModal = false;
    },
    checkIsDateField(value) {
      const isoDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (isoDateTimeRegex.test(value) || isoDateRegex.test(value)) {
        const format = this.getDefaultDateFormat
          ? this.getDefaultDateFormat
          : "MM-DD-YYYY";
        return moment(value).format(format);
      }
      return value;
    },
    sumOfArray(row, step, key) {
      let array = this.getOtherEntityFieldValue(row, step, key);
      if (array && array.length) {
        array = array
          .filter((e) => e[key])
          .reduce(
            (accumulator, currentValue) => [
              ...accumulator,
              ...currentValue[key],
            ],
            []
          );
        //remove duplicate data
        array = array.filter((e) => !e.newlyAddedData);
        const sumWithInitial = array
          .filter(
            (e) => e[step.key]
            // (!step.apply_parent_filter ||
            //   (step.apply_parent_filter && e["parentDataId"] == row._id))
          )
          .reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue[step.key] || 0),
            0
          );
        return sumWithInitial || 0;
      }
      return 0;
    },
    getOtherEntityFieldValue(row, step, key) {
      if (row && step.linked_entity_id && key) {
        let value;
        if (step.selected_date_offset) {
          value =
            row[
              step.selected_date_offset +
                "#" +
                step.linked_entity_id +
                "#" +
                step.template_id +
                "#" +
                key +
                (step?.filter != "" ? "@" + step.filter : "")
            ];
        } else {
          value =
            row[
              step.linked_entity_id +
                "#" +
                step.template_id +
                "#" +
                key +
                (step?.filter != "" ? "@" + step.filter : "")
            ];
        }
        if (value) {
          return value;
        }
        return step.default_value
          ? step.default_value
          : step.field_type == "SUM" ||
            step.field_type == "AVG" ||
            step.field_type == "COUNT"
          ? 0
          : "";
      }
      return step.default_value
        ? step.default_value
        : step.field_type == "SUM" ||
          step.field_type == "AVG" ||
          step.field_type == "COUNT"
        ? 0
        : "";
    },
    getTFields(temp) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(temp));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    getFieldOptions(filter) {
      if (filter.key && filter.key.includes("#") && this.templateFieldsObj) {
        let t = filter?.main_entity_field
          ? filter.key.split("#")[0]
          : filter.key.split("#")[1];
        let key = filter?.main_entity_field
          ? filter.key
          : filter.key.split("#")[1] + "#" + filter.key.split("#")[2];
        let field = (this.templateFieldsObj[t] || []).find((e) => e.key == key);
        return field?.options || [];
      }
      return [];
    },
    async getRequiredTemplates() {
      let templateIds = [];
      this.payload.filter_fields.forEach((e) => {
        if (e?.type == "SELECT" && e.key && e.key.includes("#")) {
          let tempId = e?.main_entity_field
            ? e.key.split("#")[0]
            : e.key.split("#")[1];
          templateIds.push(tempId);
        }
      });
      this.templateFieldsObj = {};
      await Promise.all([
        this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          get_all: true,
          include_templates: templateIds,
          populate_data: true,
        }),
        this.$store.dispatch("globalVariables/fetchGlobalVariables", {
          get_all: true,
          company_id: this.company_id,
        }),
      ]);
      if (this.getAllCompanyTemplatesData?.data) {
        (this.getAllCompanyTemplatesData.data || []).forEach((template) => {
          this.templateFieldsObj[template._id] = this.getTFields(template);
        });
      }
    },
    checkAndGetFilters() {
      this.payload.filter_fields.forEach((e) => {
        if (e.filters && e.filters.length) {
          (e.filters || []).forEach((f) => {
            if (this.dependentFilters.map((e) => e.key).indexOf(e.key) == -1) {
              this.dependentFilters.push(e);
            }
            this.filterForm[f.value_field] = this.selectedFileds[f.value_field];
          });
        }
      });
    },
    checkIsNumberField(step) {
      if (
        step.field_type == "SUM" ||
        step.field_type == "FORMULA" ||
        step.field_type == "AVG" ||
        step.field_type == "COUNT"
      ) {
        return true;
      }
      let field = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
      );
      let types = ["CURRENCY", "NUMBER", "AGGREGATE_FUNCTION"];
      if (
        field &&
        field.inputType &&
        (types.indexOf(field.inputType) !== -1 ||
          (field.inputType == "FORMULA" &&
            types.indexOf(field.result_type) !== -1))
      ) {
        return true;
      }
      return false;
    },
    async getNecessaryInfo(id) {
      // await Promise.all([
      // this.$store.dispatch(
      //   "company/fetchCompany",
      //   this.$route.path.includes("/ap")
      //     ? this.getEntityPermission.company_id
      //     : this.getActiveWorkspace.company_id
      // ),
      // this.$store.dispatch("entityViews/getEntityViewById", { id: id }),
      // ]);
      const response = await postAPICall(
        "GET",
        `/entity-view-configuration/${id}`
      );
      if (this.fromCustomDashboard) {
        this.isFilterOpen = false;
      }
      if (response?.data) {
        if (response.data?.printing_document) {
          this.pdfExport = true;
        }
        this.payload = { ...response.data };
        this.checkAndGetFilters();
        this.reset();
        this.setDatePickerDate(this.default_date, true);
        this.mapGlobalFilters();
        this.mapEntityViewFiltersData();
        await Promise.all([
          this.fetchFilterEntityData(),
          this.getRequiredTemplates(),  
        ]);
        await this.checkAndApplyFilters();
      }
    },
    mapGlobalFilters() {
      this.isRelatedToGlobalFilter = false;
      if (this.checkGlobalFilter?.entity_id && this.getGlobalFiltervalue) {
        this.payload.filter_fields.forEach((e) => {
          if (
            e?.type == "ENTITY" &&
            e.entity_id == this.checkGlobalFilter?.entity_id
          ) {
            this.isRelatedToGlobalFilter = true;
            if (e?.multiple) {
              this.$set(this.selectedFileds, e.key, [
                this.getGlobalFiltervalue,
              ]);
            } else {
              this.$set(this.selectedFileds, e.key, this.getGlobalFiltervalue);
            }
          }
        });
      }
    },
    mapEntityViewFiltersData() {
      if (
        this.filtersData &&
        this.filtersData.length &&
        this.payload.filter_fields.length
      ) {
        this.payload.filter_fields.forEach((filter) => {
          if (filter?.type == "ENTITY" && filter?.entity_id) {
            let dataObj = this.filtersData.find(
              (e) => e.entity_id == filter.entity_id
            );
            if (dataObj?.value) {
              this.selectedFileds[filter.key] = dataObj.value;
            }
          }
        });
      }
    },
    getFormulaValue(data, field) {
      if (field?.selected_fields && field.selected_fields.length) {
        let expr = "";
        field.selected_fields.forEach((e) => {
          if (e.type == "FIELD") {
            let v = this.getValue(data, e);
            let checkIsGlobalvariable = this.selectedEntityFields.find(
              (el) =>
                el.key && el.key.split("#")[1] && el.key.split("#")[1] == e.key
            );
            if (
              checkIsGlobalvariable &&
              (checkIsGlobalvariable.inputType == "DATE" ||
                checkIsGlobalvariable.result_type == "DATE")
            ) {
              v = this.dateDifference("01-01-0001", v, field.result_type);
            }
            expr = expr + v;
          } else if (
            e.type == "OPERATOR" ||
            e.type == "PARENTHESES" ||
            e.type == "NUMBER"
          ) {
            expr = expr + e.value;
          } else if (e.type == "CURRENT_DATE") {
            expr =
              expr +
              this.dateDifference("01-01-0001", moment(), field.result_type);
          }
        });
        try {
          let calculatedValue = mexp.eval(expr);
          if (isNaN(calculatedValue)) {
            return 0;
          }
          let roundedValue = calculatedValue.toFixed(2);
          roundedValue = parseFloat(roundedValue);
          return roundedValue;
        } catch (err) {
          return 0;
        }
      }
      return 0;
    },
    dateDifference(startDate, endDate, resultType = "DAYS") {
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);

      switch (resultType.toUpperCase()) {
        case "DAYS":
          return endMoment.diff(startMoment, "days");
        case "MONTHS":
          return endMoment.diff(startMoment, "months");
        case "YEARS":
          return endMoment.diff(startMoment, "years");
        default:
          return 0;
      }
    },
    getValue(data, field) {
      let step = this.payload.main_columns.find((e) => {
        if (
          e.key == field.key &&
          (field?.filter == e?.filter || (!field?.filter && !e?.filter))
        ) {
          if (!field.template_id && !field.selected_date_offset) {
            return true;
          } else if (
            field.selected_date_offset &&
            field.selected_date_offset == e.selected_date_offset
          ) {
            if (!field.template_id || field.template_id == e.template_id) {
              return true;
            }
          } else if (
            field.template_id &&
            field.template_id == e.template_id &&
            !e.selected_date_offset
          ) {
            return true;
          }
        }
      });
      if (step) {
        if (step?.field_type == "DISPLAY") {
          return this.getFieldValue(data, step);
        }
        let columnKey = this.getProp(step);
        return data[columnKey] || 0;
      }
      return 0;
    },
    setDatePickerDate(value, addValue) {
      let data = this.getDatePickerHelper1(value);
      this.payload.filter_fields.forEach((filter) => {
        if (filter.type == "DATE" && filter.filter_type == "DATE_RANGE") {
          this.$set(this.selectedFileds, filter.key, data.value);
        }
        if (
          filter.type == "DATE" &&
          filter.filter_type == "PICKER_OPTIONS" &&
          addValue
        ) {
          this.$set(this.selectedFileds, filter.key, value);
        }
      });
    },
    getProp(step) {
      if (step?.linked_entity_id && step?.template_id && step?.key) {
        if (
          step?.selected_date_offset &&
          (step.field_type == "SUM" ||
            step.field_type == "AGGREGATION_SUM" ||
            step.field_type == "AVG" ||
            step.field_type == "COUNT")
        ) {
          return (
            step.selected_date_offset +
            "#" +
            step.linked_entity_id +
            "#" +
            step.template_id +
            "#" +
            step.key +
            (step?.filter != "" ? "@" + step.filter : "")
          );
        }
        return (
          step.linked_entity_id +
          "#" +
          step.template_id +
          "#" +
          step.key +
          (step?.filter != "" ? "@" + step.filter : "")
        );
      }
      return step.key;
    },
    getDataTableData(data, add, key) {
      let result = [],
        total = {};
      (data || []).forEach((row) => {
        let obj = {};
        (add
          ? (this.payload.main_columns || []).filter(
              (e) => e.is_hide_column !== true
            )
          : this.payload.main_columns || []
        ).forEach((step, i) => {
          if (key) {
            obj[step[key]] = this.getMappedDataRow(step, row, i);
            if (i == 0) {
              total[step[key]] = "Total";
            } else {
              if (this.isNumber(obj[step[key]])) {
                if (total[step[key]]) {
                  total[step[key]] =
                    Number(obj[step[key]]) + Number(total[step[key]]);
                } else {
                  total[step[key]] = Number(obj[step[key]]);
                }
              } else {
                total[step[key]] = "  ";
              }
            }
          } else {
            obj[this.getProp(step)] = this.getMappedDataRow(step, row, i);
            if (i == 0) {
              total[this.getProp(step)] = "Total";
            } else {
              if (this.isNumber(obj[this.getProp(step)])) {
                if (total[this.getProp(step)]) {
                  total[this.getProp(step)] =
                    Number(obj[this.getProp(step)]) +
                    Number(total[this.getProp(step)]);
                } else {
                  total[this.getProp(step)] = Number(obj[this.getProp(step)]);
                }
              } else {
                total[this.getProp(step)] = "  ";
              }
            }
          }
        });
        result.push(obj);
      });
      if (add && this.payload.include_totals_in_export) {
        result.push(total);
      }
      return result;
    },
    getMappedDataRow(step, data, index) {
      if (step.main_entity_field && step.field_type == "DISPLAY") {
        return this.getFieldValue(data, step);
      } else if (step.entity_id && step.field_type == "FILTER") {
        if (
          this.appliedFilters &&
          this.appliedFilters[step.template_id + "#" + step.key] &&
          (this.allEntityDataList(step.entity_id) || []) &&
          (this.allEntityDataList(step.entity_id) || []).find(
            (e) =>
              e._id == this.appliedFilters[step.template_id + "#" + step.key]
          )
        ) {
          return this.getLabel(
            (this.allEntityDataList(step.entity_id) || []).find(
              (e) =>
                e._id == this.appliedFilters[step.template_id + "#" + step.key]
            ),
            index + 1,
            step.entity_id
          );
        } else {
          return "All";
        }
      } else if (!step.main_entity_field && step.linked_entity_id) {
        if (
          step.field_type == "SUM" ||
          step.field_type == "AVG" ||
          step.field_type == "COUNT"
        ) {
          let value = this.getOtherEntityFieldValue(data, step, step.key);
          return this.formatNumber(value);
        } else if (step.field_type == "DISPLAY") {
          if (
            this.getOtherEntityFieldValue(data, step, step.key) &&
            typeof this.getOtherEntityFieldValue(data, step, step.key) ==
              "object" &&
            this.getOtherEntityFieldValue(data, step, step.key).length == 1
          ) {
            return this.checkIsDateField(
              this.getOtherEntityFieldValue(data, step, step.key)[0][step.key]
            );
          } else if (
            this.getOtherEntityFieldValue(data, step, step.key) &&
            typeof this.getOtherEntityFieldValue(data, step, step.key) ==
              "object" &&
            this.getOtherEntityFieldValue(data, step, step.key).length > 1
          ) {
            if (step?.allow_view_details && step?.detailed_entity_view_id) {
              return "MULTIPLE";
            } else {
              return this.getOtherEntityFieldValue(data, step, step.key)
                .map((dt) => this.checkIsDateField(dt[step.key]))
                .filter((dt) => dt && dt != "" && dt != null)
                .join(", ");
            }
          } else {
            return step.default_value ? step.default_value : "";
          }
        } else if (step.field_type == "AGGREGATION_SUM") {
          if (this.getOtherEntityFieldValue(data, step, step.data_table_key)) {
            return this.sumOfArray(data, step, step.data_table_key);
          } else {
            return 0;
          }
        }
      } else if (step.field_type == "FORMULA") {
        return this.getFormulaValue(data, step);
      }
    },
    formatNumber(value) {
      if (!isNaN(value)) {
        if (typeof value === "string") {
          value = parseFloat(value);
        }
        return parseFloat(value.toFixed(2));
      } else {
        return value;
      }
    },
    getFilterHeaders() {
      let filters = [];
      if (this.payload.include_filters_in_export) {
        filters.push([this.payload.name]);
        (this.payload.filter_fields || []).forEach((filter) => {
          let filterData = [];
          if (
            this.selectedFileds[filter.key] &&
            this.selectedFileds[filter.key] !== "all"
          ) {
            let value = this.selectedFileds[filter.key];
            if (filter.key == "currentEntity") {
              let label = "";
              if (typeof value == "object" && value.length) {
                value.forEach((el) => {
                  label =
                    label +
                    this.getLabel(
                      (this.allEntityDataList(filter.entity_id) || []).find(
                        (e) => el == e._id
                      ),
                      1,
                      filter.entity_id
                    );
                });
              } else {
                label = this.getLabel(
                  (this.allEntityDataList(filter.entity_id) || []).find(
                    (e) => value == e._id
                  ),
                  1,
                  filter.entity_id
                );
              }
              filterData.push(filter.label);
              filterData.push(label);
              filters.push(filterData);
            } else if (filter.type == "ENTITY") {
              let label = "";
              if (typeof value == "object" && value.length) {
                value.forEach((el) => {
                  label =
                    label +
                    this.getLabel(
                      (this.allEntityDataList(filter.entity_id) || []).find(
                        (e) => el == e._id
                      ),
                      1,
                      filter.entity_id
                    );
                });
              } else {
                label = this.getLabel(
                  (this.allEntityDataList(filter.entity_id) || []).find(
                    (e) => value == e._id
                  ),
                  1,
                  filter.entity_id
                );
              }
              filterData.push(filter.label);
              filterData.push(label);
              filters.push(filterData);
            } else if (
              filter.type == "DATE" &&
              filter.filter_type == "DATE_RANGE"
            ) {
              let label =
                moment(value && value[0] ? value[0] : value).format(
                  "YYYY-MM-DD"
                ) +
                " - " +
                moment(value && value[1] ? value[1] : value).format(
                  "YYYY-MM-DD"
                );
              filterData.push(filter.label);
              filterData.push(label);
              filters.push(filterData);
            } else if (filter.type == "SELECT" && value) {
              let label = "";
              if (value && typeof value == "object") {
                label = value.join(",");
              } else {
                label = value;
              }
              filterData.push(filter.label);
              filterData.push(label);
              filters.push(filterData);
            }
          }
        });
        if (filters.length) {
          filters.push([]);
        }
      }
      return filters;
    },
    exportToExcel() {
      let dataArray = this.getDataTableData(this.entityViewData, true);
      const keys = Array.from(
        new Set(dataArray.flatMap((obj) => Object.keys(obj)))
      );
      const labels = (this.payload.main_columns || [])
        .filter((e) => e.is_hide_column !== true)
        .flatMap((e) => e.label);
      let filters = this.getFilterHeaders();
      const sheetData = [
        ...filters,
        labels,
        ...dataArray.map((item) => keys.map((key) => item[key] || "")),
      ];
      const ws = XLSX.utils.aoa_to_sheet(sheetData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(
        wb,
        (this.payload.name ? this.payload.name : "data") + ".xlsx"
      );
    },
    async exportToPDF() {
      if (!this.payload.printing_document) {
        this.$notify.warning({
          title: "Warning",
          message: "Can't download. Document template is not configured",
        });
        return;
      }
      this.loading = true;
      this.loadingText = "Generating PDF";
      let filters = {};
      this.getFilterHeaders().map((flt) => {
        if (flt.length > 1) {
          filters = { ...filters, ...{ [flt[0]]: flt[1] } };
        }
      });
      let params = {
        entity_view_id: this.payload._id,
        data: this.data,
        filters: filters,
        columns: this.payload.main_columns,
        name: this.payload.name,
        document_id: this.payload.printing_document,
        filter_fields: this.payload?.filter_fields
          ? this.payload?.filter_fields
          : [],
        aggregations: this.aggregationValues,
      };
      await this.$store.dispatch("entities/entityViewsExport", params);
      if (
        this.getEntityViewsPDFExport &&
        this.getEntityViewsPDFExport?.success &&
        this.getEntityViewsPDFExport?.data?.length
      ) {
        await this.downloadEntityDataPdf(
          this.getEntityViewsPDFExport.data[0],
          this.payload?.name
        );
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error while generating the document",
        });
      }
      this.loading = false;
      this.loadingText = "Fetching data...";
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Total";
          return;
        }
        const values = data.map((item) => {
          if (item[column.property]) {
            return Number(item[column.property]);
          }
          let [entity, template_id, key] = column.property.split("#");
          if (
            entity &&
            item?.entityData?.[template_id]?.[key] &&
            Number(item.entityData[template_id][key])
          ) {
            return Number(item.entityData[template_id][key]);
          }
          if (column.property) {
            let field = this.payload.main_columns.find(
              (e) =>
                (e.key == column.property && e.field_type == "FORMULA") ||
                e.linked_entity_id + "#" + e.template_id + "#" + e.key ==
                  column.property
            );
            if (field?.field_type == "FORMULA") {
              return Number(this.getFormulaValue(item, field));
            } else if (field?.field_type == "AGGREGATION_SUM") {
              return Number(
                this.sumOfArray(item, field, field.data_table_key) || []
              );
            }
          }
        });
        if (!values.every((value) => isNaN(value))) {
          let calculatedValue = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          let roundedValue = calculatedValue.toFixed(2);
          sums[index] = parseFloat(roundedValue);
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    isNumber(value) {
      if (typeof value === "number" && !isNaN(value)) {
        return true;
      }
      if (typeof value === "string") {
        return /^[+-]?\d+(\.\d+)?$/.test(value) && !isNaN(parseFloat(value));
      }
      return false;
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    getField(step) {
      if (step.field_type != "FORMULA") {
        if (step.main_entity_field) {
          return this.selectedEntityFields.find(
            (e) =>
              e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
          );
        } else {
          return this.otherEntitiesAllFields[step.linked_entity_id].find(
            (e) => e?.key == step.template_id + "#" + step.key
          );
        }
      }
    },
    getFieldValue(row, step) {
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.key
      );
      if (checkIsGlobalvariable) {
        if (
          checkIsGlobalvariable.is_global_variable &&
          checkIsGlobalvariable.global_variable_id
        ) {
          let globalVariable = (this.globalVariablesData || []).find(
            (e) => e._id == checkIsGlobalvariable.global_variable_id
          );
          if (globalVariable) {
            if (
              globalVariable.input_type == "IMAGE" &&
              globalVariable.image_url
            ) {
              return globalVariable.image_url;
            } else if (globalVariable.value) return globalVariable.value;
          }
        }
        step["type"] = checkIsGlobalvariable.inputType;
      }
      if (
        step.type == "DATE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        // const format = this.getDefaultDateFormat
        //   ? this.getDefaultDateFormat
        //   : "MM-DD-YYYY";
        // return moment(row.entityData[step.template_id][step.key]).format(
        //   format
        // );
        return row.entityData[step.template_id][step.key];
      } else if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.key]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.key] == "object" &&
            row.entityData[step.template_id][step.key].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.key].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(",");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.key]];
          }
        }
        return "";
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.key && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.key]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.key].files &&
          row.entityData[step.template_id][step.key].files.length
            ? row.entityData[step.template_id][step.key].files
            : row.entityData[step.template_id][step.key];
        this.documentSearchData = this.documentsData;
        return row.entityData[step.template_id][step.key].files &&
          row.entityData[step.template_id][step.key].files.length
          ? row.entityData[step.template_id][step.key].files
          : row.entityData[step.template_id][step.key];
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.key]
      ) {
        if (
          typeof row.entityData[step.template_id][step.key] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.key])
        ) {
          return parseFloat(row.entityData[step.template_id][step.key]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.key]
            ? row.entityData[step.template_id][step.key]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.key]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.key
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.key]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.key];
          } else {
            result = row.entityData[step.template_id][step.key];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.key]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.key
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) => e.value == row.entityData[step.template_id][step.key]
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.key];
            }
          } else {
            result = row.entityData[step.template_id][step.key];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.key]
        ) {
          return typeof row.entityData[step.template_id][step.key] == "number"
            ? row.entityData[step.template_id][step.key]
            : "";
        } else if (step.type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.key] &&
            typeof row.entityData[step.template_id][step.key] == "object" &&
            row.entityData[step.template_id][step.key].length
          ) {
            return row.entityData[step.template_id][step.key].length
              ? row.entityData[step.template_id][step.key].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.key].length
              ? row.entityData[step.template_id][step.key]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("HH:mm:ss") +
              " - " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("HH:mm:ss")
            );
          }
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("MM-DD-YYYY hh:mm:ss A") +
              " To " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("MM-DD-YYYY hh:mm:ss A")
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.key].length
        ) {
          if (row.entityData[step.template_id][step.key].length) {
            return (
              this.$moment(
                row.entityData[step.template_id][step.key][0]
              ).format("MM-DD-YYYY") +
              " To " +
              this.$moment(
                row.entityData[step.template_id][step.key][1]
              ).format("MM-DD-YYYY")
            );
          }
        }
        if (row.entityData[step.template_id][step.key + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.key + "/name"] ==
              "object" &&
            row.entityData[step.template_id][step.key + "/name"].length
          ) {
            return row.entityData[step.template_id][step.key + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.key + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.key + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.key + "/name"];
        } else {
          let value = row.entityData[step.template_id][step.key]
            ? row.entityData[step.template_id][step.key]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.map((e) => this.checkIsDateField(e)).join(",");
            }
            return value;
          }
          return "";
        }
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        typeof row.entityData[step.template_id][step.key] == "number"
      ) {
        return row.entityData[step.template_id][step.key];
      }
      return "";
    },
    allEntityDataList(entity_id) {
      if (
        entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[entity_id] &&
        this.entityDataMapping[entity_id].data
      ) {
        return this.entityDataMapping[entity_id].data;
      }
      return [];
    },
    getLabel(item, i, entity_id) {
      if (
        entity_id &&
        this.entityDataMapping &&
        this.entityDataMapping[entity_id] &&
        this.entityDataMapping[entity_id].selectedEntity
      ) {
        let selectedEntity = this.entityDataMapping[entity_id].selectedEntity;
        let name = "data " + i;
        // let name =
        //   item &&
        //   item.entity_prime_data &&
        //   Object.keys(item.entity_prime_data) &&
        //   item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
        //     ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
        //     : "data " + i;
        if (selectedEntity && selectedEntity.primaryFields) {
          let primaryField = selectedEntity.primaryFields[0];
          if (
            item?.entityData?.[primaryField?.template_id]?.[primaryField?.key]
          ) {
            name =
              primaryField.inputType == "ENTITY"
                ? item.entityData[primaryField.template_id][
                    primaryField.key + "/name"
                  ]
                : item.entityData[primaryField.template_id][primaryField.key];
          }
        }
        return name;
      }
      return "Data " + i;
    },
    reset() {
      this.payload.filter_fields.forEach((fl) => {
        if (fl.type == "ENTITY" || fl.type == "SELECT") {
          if (fl.required) {
            this.$set(this.selectedFileds, fl.key, fl.multiple ? [] : "");
          } else {
            this.$set(
              this.selectedFileds,
              fl.key,
              fl.multiple ? ["all"] : "all"
            );
          }
          if (
            this.isApplicationUserSide &&
            fl.type == "ENTITY" &&
            fl.isApplicationUser
          ) {
            if (fl.entity_id == this.getActiveContactType.contact_type._id) {
              this.$set(
                this.selectedFileds,
                fl.key,
                fl.multiple
                  ? [this.getActiveContactType.account_data_id]
                  : this.getActiveContactType.account_data_id
              );
            }
          }
        } else if (fl.type == "DATE") {
          let data = this.getDatePickerHelper(this.default_date);
          if (fl.filter_type == "DATE_RANGE") {
            this.$set(this.selectedFileds, fl.key, [data.value, data.today]);
          } else {
            this.$set(this.selectedFileds, fl.key, this.default_date);
          }
        }
      });
    },
    resetFilters() {
      this.reset();
      this.fetchEntitiesData();
    },
    applyFilters() {
      this.appliedFilters = {};
      let empty = this.payload.filter_fields.find((fl) => {
        if (
          fl.required &&
          (!this.selectedFileds[fl.key] || !this.selectedFileds[fl.key].length)
        ) {
          return true;
        }
      });
      if (empty?.label) {
        this.$message({
          message: "Warning, Please select " + empty.label,
          type: "warning",
        });
        return;
      }
      this.checkAndApplyFilters();
    },
    dateFilterCount(type) {
      return (this.payload.filter_fields || []).filter(
        (e) => e.type == "DATE" && e.filter_type == type
      ).length;
    },
    checkPickerOptionOnlySelected() {
      let isPickerSelected = false;
      let rangeCount = this.dateFilterCount("DATE_RANGE");
      let pickerCount = this.dateFilterCount("PICKER_OPTIONS");
      if (pickerCount && !rangeCount) {
        isPickerSelected = true;
      }
      return isPickerSelected;
    },
    checkAndApplyFilters() {
      let mainFilters = [],
        currentEntityDataids = [];
      let isPickerSelected = this.checkPickerOptionOnlySelected();
      this.payload.filter_fields.forEach((el) => {
        let value = this.selectedFileds[el.key];
        this.$set(this.appliedFilters, el.key, value);
        if (el.key == "currentEntity") {
          if (value && value.indexOf("all") === -1) {
            currentEntityDataids = [
              ...currentEntityDataids,
              ...(typeof value == "object" ? value : [value]),
            ];
          }
        } else if (el.type == "ENTITY") {
          let filter = {
            field: el.key,
            operator: "in",
            value: typeof value == "object" ? value : [value],
            value_type: "",
            value_source: "",
            data_type: "OBJECT_ID",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            data_source: "ENTITY_DATA_LIST",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (el.type == "DATE" && el.filter_type == "DATE_RANGE") {
          let filter = {
            field: el.key,
            operator: "between",
            value: [
              value && value[0] ? value[0] : value,
              value && value[1] ? value[1] : value,
            ],
            value_type: "",
            value_source: "",
            data_type: "DATE",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (el.type == "SELECT" && value) {
          let filter = {
            field: el.key,
            operator: "in",
            value: typeof value == "object" ? value : [value],
            value_type: "",
            value_source: "",
            data_type: "ARRAY",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            data_source: "ENTITY_DATA_LIST",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        } else if (
          isPickerSelected &&
          el.type == "DATE" &&
          el.filter_type == "PICKER_OPTIONS"
        ) {
          let dateValueObj = this.getDatePickerHelper1(value);
          let dateValue1 = dateValueObj.value;
          let filter = {
            field: el.key.replace("/picker", ""),
            operator: "between",
            value: [
              dateValue1 && dateValue1[0] ? dateValue1[0] : dateValue1,
              dateValue1 && dateValue1[1] ? dateValue1[1] : dateValue1,
            ],
            value_type: "",
            value_source: "",
            data_type: "DATE",
            query_type: "AND",
            value_field: "",
            validations: {
              data_type: "",
            },
            relation_entity_id: "",
            main_entity_field: el.main_entity_field,
            linked_entity_ids: el.linked_entity_ids,
          };
          mainFilters.push(filter);
        }
      });
      this.fetchEntitiesData(mainFilters, currentEntityDataids);
    },
    mapTimeValue(date, time) {
      if (date && date.includes("T")) {
        let newData = date.split("T")[0];
        return newData + "T" + time;
      }
      return date + "T" + time;
    },
    openFiltersCollapse() {
      this.isFilterOpen = !this.isFilterOpen;
    },
    async fetchDependentEnityData() {
      if (this.dependentFilters) {
        await Promise.all(
          this.dependentFilters.map(async (el) => {
            if (el.filters && el.filters.length) {
              this.loadingFilters[el.key] = true;
              let filters = el.filters.map((fl) => {
                if (fl.data_source && fl.value_field) {
                  fl.value = this.selectedFileds[fl.value_field];
                }
                return fl;
              });
              return await this.fetchEntityData(el.entity_id, filters, el.key);
            }
            return el;
          })
        );
        this.refresh = true;
        await setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    removeAllFilters(filters) {
      return filters.filter((filter) => {
        if (filter?.data_source == "self_field") {
          if (
            filter?.value &&
            filter.value.length &&
            filter.value.includes("all")
          ) {
            return false;
          }
        }
        return true;
      });
    },
    async fetchEntityData(el, filters = [], key = "") {
      let params = {
        entity_id: el,
        template_fields_data: [],
        filters: this.removeAllFilters(filters),
        relationship: null,
        limit: 500,
        page: 1,
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable && this.getEntityRecordsForTable.data) {
        this.entityDataMapping[el] = {
          ...this.getEntityRecordsForTable,
          ...{ update: true },
        };
        this.otherEntitiesAllFields[el] = [
          ...this.getAllFieldsFromEntity(
            this.getEntityRecordsForTable.selectedEntity,
            false
          ),
        ];
      }
      if (key) {
        this.$set(this.loadingFilters, key, false);
      }
      return el;
    },
    async fetchFilterEntityData() {
      let entityFields = (this.payload.filter_fields || [])
        .filter(
          (e) =>
            e.type == "ENTITY" &&
            e.entity_id &&
            (e.key != "currentEntity" ||
              (e.key == "currentEntity" &&
                (!e.filters ||
                  !e.filters.length ||
                  !e.filters.find((f) => f.field == "self#related_to/1"))))
        )
        .flatMap((e) => e.entity_id);
      (this.payload.main_columns || []).forEach((e) => {
        if (!e.main_entity_field && e.entity_id) {
          entityFields.push(e.entity_id);
        }
      });
      entityFields = [
        ...new Set([
          ...entityFields,
          ...this.payload.other_entities.flatMap((e) => e.entity_id),
        ]),
      ];
      if (entityFields && entityFields.length) {
        this.loading = true;
        this.refresh = true;
        await Promise.all(
          entityFields.map(async (el) => {
            return await this.fetchEntityData(el);
          })
        );
        this.refresh = false;
        this.loading = false;
      }
    },
    mapAllSelectedFilters(filters) {
      return (filters || []).filter((e) => {
        if (e.operator == "in" && e.value && e.value.indexOf("all") != -1) {
          return false;
        }
        return true;
      });
    },
    async fetchEntitiesData(mainFilters, currentEntityDataids = []) {
      this.loading = true;
      this.search_string = "";
      let params = {
        entity_data_ids: currentEntityDataids,
        main_filters: this.mapAllSelectedFilters(mainFilters),
        // main_filters: mainFilters,
        ...this.payload,
      };
      await this.$store.dispatch("entities/fetchEntityViewsData", params);
      this.data = [];
      this.searchData = [];
      if (this.getEntityViewsData?.data) {
        // this.data = this.filterIgnoreFieldsData(this.getEntityViewsData.data);
        this.entityViewData = this.getEntityViewsData.data || [];
        if (this.getEntityViewsData.selectedEntity) {
          this.selectedEntityFields = this.getAllFieldsFromEntity(
            this.getEntityViewsData.selectedEntity,
            false
          );
          this.currentEntity = { ...this.getEntityViewsData.selectedEntity };
          if (this.currentEntity?.templates) {
            this.currentEntity.templates.forEach((t) => {
              let temp = JSON.parse(JSON.stringify(t));
              if (temp?.templateInfo?.rules) {
                let colorRules = temp.templateInfo.rules.filter((e) => {
                  if (
                    e?.actions &&
                    e.actions.find((el) => el.operator_type == "CHANGE_COLOR")
                  ) {
                    return e;
                  }
                });
                if (colorRules && colorRules.length) {
                  let fields = [];
                  temp.templateInfo.sections.forEach((e) => {
                    fields = [...fields, ...e.fields];
                  });
                  this.entitytemplateColorRules[temp.template_id] = {
                    fields: fields,
                    colorRules: colorRules,
                  };
                }
              }
            });
          }
        }
        this.data = this.filterIgnoreFieldsData(
          this.getDataTableData(this.entityViewData)
        );
        this.searchData = [...this.data];
        this.fetchAggregationValues();
      }
      this.loading = false;
    },
    filterIgnoreFieldsData(data) {
      let ignoreColumns = this.payload.main_columns.filter(
        (e) => e.ignore_if_empty
      );
      if (ignoreColumns.length) {
        return data.filter((dt) => {
          let results = [];
          ignoreColumns.forEach((col) => {
            results.push(this.checkDataIgnored(dt, col));
          });
          if (results.every((el) => !el)) {
            return false;
          }
          return true;
        });
      }
      return data;
    },
    checkDataIgnored(data, column) {
      let result = false;
      let key = this.getProp(column);
      if (data && data[key]) {
        result = true;
      }
      return result;
    },
    async backToEntitiesViews() {
      let query = await this.getNavigationQuery(this.$route.query);
      this.$router.push({ path: "/entity-views", query: query });
    },
    getFieldStyle(column, data) {
      if (
        this.entitytemplateColorRules &&
        this.entitytemplateColorRules[column.template_id] &&
        this.entitytemplateColorRules[column.template_id]["colorRules"]
      ) {
        let colorRules = this.entitytemplateColorRules[column.template_id][
          "colorRules"
        ].filter((e) => {
          if (
            e?.actions &&
            e.actions.find(
              (el) =>
                el.operator_type == "CHANGE_COLOR" &&
                el.selectField == column.key
            )
          ) {
            return e;
          }
        });
        let style = this.checkEntityDataConditions(
          colorRules,
          data.entityData[column.template_id],
          this.entitytemplateColorRules[column.template_id]["fields"]
        );
        return style;
      }
      return "";
    },
    getCurrencyFormat(value, field) {
      let label = "";
      if (field?.inputType == "CURRENCY") {
        let type = field.validations.currency
          ? field.validations.currency
          : "USD";
        let locale = field.validations.locale
          ? field.validations.locale
          : "en-US";
        label = new Intl.NumberFormat(locale, {
          style: "currency",
          currency: type,
        }).format(value);
      } else if (
        field?.inputType == "AGGREGATE_FUNCTION" ||
        field?.inputType == "FORMULA"
      ) {
        let isCurrencyField = (field.selected_fields || []).find((el) => {
          if (
            el?.inputType == "CURRENCY" ||
            el?.input_type == "CURRENCY" ||
            el?.result_type == "CURRENCY"
          ) {
            return true;
          }
        });
        if (
          !isCurrencyField &&
          field?.inputType == "FORMULA" &&
          field.result_type == "CURRENCY" &&
          field.selected_fields?.[0]?.validations?.currency
        ) {
          let locale = field.validations?.locale || "en-US";
          return (label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: field.selected_fields[0].validations.currency,
          }).format(value));
        }
        if (isCurrencyField) {
          if (field?.inputType == "AGGREGATE_FUNCTION") {
            let dataTableColumn = this.getDataTableField(
              field.input_type == 'ENTITY_VARIABLE' ? field.global_variable_entity_field_template_id : field.template_id, isCurrencyField.key
            );
            if (dataTableColumn) {
              if (dataTableColumn?.validations) {
                isCurrencyField["validations"] = dataTableColumn.validations;
              }
            }
          } else {
            if (field?.validations) {
              isCurrencyField["validations"] = field.validations;
            }
          }
          let type =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.currency
              ? isCurrencyField.validations.currency
              : "USD";
          let locale =
            isCurrencyField &&
            isCurrencyField.validations &&
            isCurrencyField.validations.locale
              ? isCurrencyField.validations.locale
              : "en-US";
          label = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: type,
          }).format(value);
        } else {
          label = value;
        }
      } else {
        label = value;
      }
      return label;
    },
    fetchAggregationValues() {
      (this.payload.aggregations || []).map((aggregation) => {
        if (aggregation.type == "Display") {
          let [key, filter] = aggregation.displayField.includes("@")
            ? aggregation.displayField.split("@")
            : [aggregation.displayField, ""];
          this.aggregationValues[aggregation.label] = this.computeAggregation(
            `${key.split("#")[1]}#${key.split("#")[2]}#${key.split("#")[3]}` +
              (filter != "" ? "@" + filter : ""),
            key.split("#")[0].toUpperCase()
          ).toFixed(2);
        } else {
          let expr = "";
          aggregation.selected_fields.map((fd) => {
            if (fd.type == "FIELD") {
              let value = this.computeAggregation(
                `${fd.linked_entity_id}#${fd.template_id}#${fd.key}` +
                  (fd?.filter != "" ? "@" + fd?.filter : ""),
                fd.agg_type
              );
              expr += value;
            } else {
              expr += fd.value;
            }
          });
          try {
            this.aggregationValues[aggregation.label] = mexp
              .eval(expr)
              .toFixed(2);
          } catch (err) {
            this.aggregationValues[aggregation.label] = "0.00";
          }
        }
        this.aggregationValues[aggregation.label] =
          this.getAggregationValue(aggregation);
      });
    },
    computeAggregation(field, type) {
      let sum = 0;
      this.searchData.map((dt) => {
        sum += Number(dt[field]);
      });
      if (type == "SUM") {
        return sum;
      } else {
        return Number(sum / this.searchData.length);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outer-div {
  display: flex !important;
  justify-content: space-between !important;
}
.head-title {
  font-size: 18px;
}
.filters-cards {
  display: flex;
  grid-gap: 10px;
  margin-top: 10px;
  grid-template-columns: repeat(4, 1fr);
  align-items: end;
}

.entity-buttons {
  display: flex;
}

.reset-button {
  margin-left: 10px;
}

.run-button {
  font-size: 0.875rem;
}
</style>
